import { useState, useEffect } from "react";
import { Helmet} from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import faceImg from "../assets/img/web-icon.svg";

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = [ "Web Developer", "Web Designer", "UX/UI Designer" ];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(380 - Math.random() * 20);
    const period = 1000

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        },delta)

        return () => { clearInterval(ticker)};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    document.title = `Arjen Graafland | Home`;

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length +1);

        setText(updatedText);

        if (isDeleting) {
            setDelta(prevDelta => prevDelta / 2)
        }
        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if(isDeleting && updatedText === '') {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(600);
        }
    }

    return (
        <section className="banner" id="home">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Arjen Graafland | Home</title>
                <link rel="canonical" href="https://arjengraafland.nl/" />
            </Helmet>
            <div className="flex items-center w-full">
                <div className="w-1/3 md:h-auto md:w-1/5 xl:h-auto xl:w-1/12 pl-8 pt-5">
                    <div className="py-6"><a href="https://github.com/a-gfld"><FontAwesomeIcon icon={faGithub} size="xl" className="text-black dark:text-white" /></a></div>
                    <div><a href="https://www.linkedin.com/in/arjen-graafland-b65b2b290"><FontAwesomeIcon icon={faLinkedinIn} size="xl" className="text-black dark:text-white" /></a></div>
                </div>
                <div className="w-full md:w-2/5 xl:w-5/12 text-left mr-16">
                    <h1 className="mb-2">{`Hi I'm Arjen`}</h1>
                    <h2><span className="wrap typewriter">{text}</span></h2>
                    <div className="mt-4 flex flex-wrap w-full gap-x-3 gap-y-2">
                        <div className="item-tag">JavaScript</div>
                        <div className="item-tag">React</div>
                        <div className="item-tag">PHP</div>
                        <div className="item-tag">Phalcon</div>
                        <div className="item-tag">HTML</div>
                        <div className="item-tag">CSS</div>
                        <div className="item-tag">Swagger</div>
                        <div className="item-tag">Maizzle</div>
                    </div>
                    <a href="/contact"><button className="btn-contact mt-6">Contact Us <FontAwesomeIcon className="text-xs" icon={faChevronRight} /></button></a>
                </div>
                <div className="w-full hidden md:block md:w-2/5 xl:w-5/12">
                    <img src={faceImg} alt="Arjen Graafland" className="w-auto h-[62.5vh]"/>
                </div>
            </div>
        </section>
    )
}