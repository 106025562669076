import React from "react";
import {Parallax, ParallaxProvider} from 'react-scroll-parallax';
import swaggerApi from "../assets/img/swagger-api.png";
import emailTemplate from "../assets/img/emailtemplate.png";
import textScraper from "../assets/img/text-scraper-excel.png";
import selenium from "../assets/img/Selenium.png";
import {Helmet} from "react-helmet";

const Works = () => {
    return (
        <section className="works" id="works">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Arjen Graafland | Works</title>
                <link rel="canonical" href="https://arjengraafland.nl/works" />
            </Helmet>
            <h1 className="-mb-12 text-3xl text-center md:text-left md:text-4xl">Here are some of my works</h1>
            <div className="min-h-[100vh] min-w-[100vw] desktop">
                <ParallaxProvider>
                    <div className="parallax-grid md">
                        <div className="works-item-parallax mt-[10rem]">
                            <div className="img-wrapper"><img src={swaggerApi} alt="AFAS API using Swagger API Documentation" className="works-item-img"/></div>
                            <div className="mt-8">
                                <div className="flex w-full justify-between">
                                    <h4 className="uppercase">Youseeq B.V.</h4>
                                    <div className="flex gap-4 -mt-1.5">
                                        <div className="item-tag">Swagger</div>
                                        <div className="item-tag">API</div>
                                        <div className="item-tag">Phalcon</div>
                                        <div className="item-tag">PHP</div>
                                    </div>
                                    <div className="w-10"></div>
                                    <h4>2023</h4>
                                </div>
                            </div>
                        </div>
                        <Parallax speed={-10}>
                            <div className="works-item-parallax">
                                <div className="img-wrapper"><img src={emailTemplate} alt="Email templates using Maizzle" className="works-item-img"/></div>
                                <div className="mt-8">
                                    <div className="flex w-full justify-between">
                                        <h4 className="uppercase">Youseeq B.V.</h4>
                                        <div className="flex gap-4 -mt-1.5">
                                            <div className="item-tag">Maizzle</div>
                                            <div className="item-tag">HTML</div>
                                            <div className="item-tag">Phalcon</div>
                                        </div>
                                        <div className="w-20"></div>
                                        <h4>2023</h4>
                                    </div>
                                </div>
                            </div>
                        </Parallax>
                        <div className="works-item-parallax">
                            <div className="img-wrapper"><img src={textScraper} alt="Text scraper to excel file using Jquery and Regex" className="works-item-img"/></div>
                            <div className="mt-8">
                                <div className="flex w-full justify-between">
                                    <h4 className="uppercase">Maintenanz</h4>
                                    <div className="flex gap-4 -mt-1.5">
                                        <div className="item-tag">JQuery</div>
                                        <div className="item-tag">Regex</div>
                                    </div>
                                    <div className="w-36"></div>
                                    <h4>2023</h4>
                                </div>
                            </div>
                        </div>
                        <Parallax speed={-10}>
                            <div className="works-item-parallax">
                                <div className="img-wrapper"><img src={selenium} alt="Test Automation using Selenium WebDriver" className="works-item-img max-w-[150%]"/></div>
                                <div className="mt-8">
                                    <div className="flex w-full justify-between">
                                        <h4 className="uppercase">Youseeq B.V.</h4>
                                        <div className="flex gap-4 -mt-1.5">
                                            <div className="item-tag">Selenium</div>
                                            <div className="item-tag">PHP</div>
                                        </div>
                                        <div className="w-36"></div>
                                        <h4>2023</h4>
                                    </div>
                                </div>
                            </div>
                        </Parallax>
                    </div>
                </ParallaxProvider>
            </div>
            <div className="mobile">
                <div className="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 mt-12">
                    <div className="works-item mt-[3rem]">
                        <div className="img-wrapper"><img src={swaggerApi} alt="AFAS API using Swagger API Documentation" className="works-item-img"/></div>
                        <div className="mt-8">
                            <div className="flex w-full justify-between">
                                <h4>Dec, 2023</h4>
                                <div className="flex gap-2 -mt-1.5">
                                    <div className="item-tag">Swagger</div>
                                    <div className="item-tag">API</div>
                                    <div className="item-tag">Phalcon</div>
                                </div>
                            </div>
                            <h4 className="uppercase mt-3 font-semibold !text-lightblue">Youseeq B.V.</h4>
                            <h3 className="text-[20px] font-semibold mt-2">AFAS Api Documented with Swagger</h3>
                        </div>
                    </div>
                    <div className="works-item">
                        <div className="img-wrapper"><img src={emailTemplate} alt="Email templates using Maizzle" className="works-item-img"/></div>
                        <div className="mt-8">
                            <div className="flex w-full justify-between">
                                <h4>Dec, 2023</h4>
                                <div className="flex gap-2 -mt-1.5">
                                    <div className="item-tag">Maizzle</div>
                                    <div className="item-tag">HTML</div>
                                </div>
                            </div>
                            <h4 className="uppercase mt-3 font-semibold !text-lightblue">Youseeq B.V.</h4>
                            <h3 className="text-[20px] font-semibold mt-2">Email Template built with Maizzle</h3>
                        </div>
                    </div>
                    <div className="works-item">
                        <div className="img-wrapper"><img src={textScraper} alt="Text scraper to excel file using Jquery and Regex" className="works-item-img"/></div>
                        <div className="mt-8">
                            <div className="flex w-full justify-between">
                                <h4>Sept, 2023</h4>
                                <div className="flex gap-2 -mt-1.5">
                                    <div className="item-tag">JQuery</div>
                                    <div className="item-tag">Regex</div>
                                </div>
                            </div>
                            <h4 className="uppercase mt-3 font-semibold !text-lightblue">Maintenanz</h4>
                            <h3 className="text-[20px] font-semibold mt-2">Text Scraper to Excel File</h3>
                        </div>
                    </div>
                    <div className="works-item">
                        <div className="img-wrapper"><img src={selenium} alt="Test Automation using Selenium WebDriver" className="works-item-img max-w-[150%]"/></div>
                        <div className="mt-8">
                            <div className="flex w-full justify-between">
                                <h4>Nov, 2023</h4>
                                <div className="flex gap-2 -mt-1.5">
                                    <div className="item-tag">Selenium</div>
                                    <div className="item-tag">PHP</div>
                                </div>
                            </div>
                            <h4 className="uppercase  mt-3 font-semibold !text-lightblue">Youseeq B.V.</h4>
                            <h3 className="text-[20px] font-semibold mt-2">Automated test environment build with Selenium</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Works;