import React, { useState, useEffect } from "react";
import { Classic } from "@theme-toggles/react";
import "@theme-toggles/react/css/Classic.css";
import { NavLink } from 'react-router-dom';
import { Navbar } from "@material-tailwind/react";

function NavList() {
    return (
        <nav>
            <div className="flex space-x-4">
                <NavLink
                    to="/"
                    className="navbar-link p-1 font-bold text-black dark:text-white"
                >
                    Home
                </NavLink>
                <NavLink
                    to="/about"
                    className="navbar-link p-1 font-bold text-black dark:text-white"
                >
                    About
                </NavLink>
                {/*<NavLink*/}
                {/*    to="/skills"*/}
                {/*    className="navbar-link p-1 font-bold text-black dark:text-white"*/}
                {/*>*/}
                {/*    Skills*/}
                {/*</NavLink>*/}
                <NavLink
                    to="/works"
                    className="navbar-link p-1 font-bold text-black dark:text-white"
                >
                    Works
                </NavLink>
                <NavLink
                    to="/contact"
                    className="navbar-link p-1 font-bold text-black dark:text-white">
                    Contact
                </NavLink>
            </div>
        </nav>
    );
}

export function NavBar() {

    const [menuOpened, setMenuOpened] = useState(false);

    const toggleMenu = () => {
        setMenuOpened(!menuOpened);
    };

    const handleMenuItemClick = () => {
        if (menuOpened) {
            setMenuOpened(false);
        }
    };

    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const isDarkMode = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDarkMode);
    }, []);

    useEffect(() => {
        document.documentElement.classList.toggle('dark', darkMode);
        localStorage.setItem('darkMode', darkMode);
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode((prevMode) => !prevMode);
    };

    const handleWindowResize = () =>
        window.innerWidth >= 960;

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <Navbar expand="md" className="Navbar">
            <div className="desktop">
                <NavLink
                    to="/"
                    className="ml-0 cursor-pointer py-1.5 font-bold !text-black dark:!text-white"
                >
                    Arjen
                </NavLink>
                <div className="flex gap-6 mr-0">
                    <NavList />
                    <Classic duration={750} onToggle={toggleDarkMode} className="text-black dark:text-white" />
                </div>
            </div>
            <div className="mobile">
                <div className={`header ${menuOpened ? 'menu-opened' : ''}`}>
                    <ul className="menu">
                        <NavLink
                            to="/"
                            className="logo-btn"
                        >
                            Arjen
                        </NavLink>
                        {['Home', 'About', 'Works', 'Contact'].map(
                            (item, index) => (
                                <li key={index} className="menu-item" onClick={handleMenuItemClick}>
                                    <NavLink
                                        to={item === 'Home' ? "/" : `/${item.toLowerCase()}`}
                                        className="navbar-link group">
                                        <span className="group-hover:text-lightgray/80">{item}</span>
                                    </NavLink>
                                </li>
                            )
                        )}
                    </ul>
                    <div className="btn-container">
                        <Classic duration={750} onToggle={toggleDarkMode} className="theme-toggle-btn"/>
                        <div className="burger-container" onClick={toggleMenu}>
                            <div id="burger">
                                <div className="bar topBar"></div>
                                <div className="bar btmBar"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>
    );
}