import React, {useEffect} from "react";
import SplitType from "split-type";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {Helmet} from "react-helmet";
const About = () => {

    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        const splitTypes = document.querySelectorAll('.reveal-type')

        splitTypes.forEach((char) => {
            const text = new SplitType(char, {type: 'words'})

            gsap.from(text.words, {
                scrollTrigger: {
                    trigger: char,
                    start: "top 30%",
                    end: "bottom 40%",
                    scrub: true,
                    markers: false
                },
                opacity: 0.2,
                stagger: 0.05,
                onComplete: () => {
                    const charSplit = new SplitType(char, {type: 'chars'})

                    gsap.from(charSplit.chars, {
                        scrollTrigger: {
                            trigger: char,
                            start: "top 30%",
                            end: "bottom 40%",
                            scrub: true,
                            markers: false
                        },
                        opacity: 0.2,
                        stagger: 0.05
                    })
                }
            })
        })
    }, []);

    return (
        <section className="about min-h-screen" id="about">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Arjen Graafland | About</title>
                <link rel="canonical" href="https://arjengraafland.nl/about" />
            </Helmet>
            <div className="mt-24 mb-10 text-center sm:text-left">
                <h1 className="text-black dark:text-white mb-16 text-4xl">
                    Hi i'm Arjen Graafland
                </h1>
                <div className="flex">
                    <div className="reveal-type">
                        <p className="w-full mt-16">
                            Originating from The Hague, The Netherlands,  and lived in Voorburg until the age of 13. In 2019, a compelling opportunity led my family and me to Siebengewald, Limburg, where we discovered a spacious home at a remarkable 2/3rd of the cost compared to our previous house. It was here that the seeds of my software passion sprouted, ignited by the launch of a game server project with friends. Post my grade school finale in 2022, I stepped into the world of Software Development at KW1C.
                        </p>
                        <p className="w-full mt-24">
                            Beyond the script of code, my soul resonates with the hum of engines. Every first Tuesday, I immerse myself in the automotive symphony at Cars and Coffee, a celebration of vehicular artistry. The thrill extends to the virtual tarmac of iRacing, where my digital wheels leave rubber marks. I nurture dreams of owning a project car, shaping its essence with mods, and perhaps, watching its tire tracks etch victories on the asphalt.
                        </p>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default About;