import React, {useState} from "react";
import { motion, AnimatePresence } from 'framer-motion';
import emailjs from '@emailjs/browser';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faMessage, faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faChevronRight, faChevronUp, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet";

const Contact = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    document.title = `Arjen Graafland | Contact`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage(null);

        try {
            // eslint-disable-next-line
            const response = await emailjs.sendForm(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                e.target,
                process.env.REACT_APP_PUBLIC_KEY
            );

            e.target.reset();
            setIsSubmitting(false);
        } catch (error) {
            setErrorMessage('Something went wrong! Please try again.');
            setIsSubmitting(false);
        }
    };

    const alertVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 },
    };

    return (
        <section className="contact" id="contact">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Arjen Graafland | Contact</title>
                <link rel="canonical" href="https://arjengraafland.nl/about" />
            </Helmet>
            <div className="min-w-screen min-h-[80vh] flex items-center justify-center mt-14">
                <div className="w-full">
                    <div className="md:flex w-full">
                        <div className="hidden md:block w-1/2 py-10">
                            <div className="text-left mb-10">
                                <h1 className="mb-10">Let’s connect</h1>
                                <p className="uppercase w-2/3 text-base">Now it's time to tell the whole world about your business</p>
                                <button className="cursor-default"><a href="mailto:info@arjengraafland.nl" className="btn-contact mt-6 flex uppercase cursor-pointer">Send an email <FontAwesomeIcon className="text-xs icon-up mt-1 ml-2" icon={ faChevronUp } /><FontAwesomeIcon className="text-xs hidden icon-right mt-1 ml-2 pr-1" icon={ faChevronRight } /></a></button>
                            </div>
                        </div>
                        <FontAwesomeIcon icon={faSpinner} spin size="2xl" className="text-darkgray dark:text-lightgray absolute right-[25.6%] bottom-2/3 hidden" id="form-loader"/>
                        <form className="form w-full max-w-[800px]" id="contact-form" method="post" onSubmit={handleSubmit}>
                            <div className="text-center mb-10">
                                <h3 className="font-bold text-3xl uppercase">Send us a message</h3>
                            </div>
                            <div>
                                <div className="flex">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-3 mb-3">
                                        <div className="w-full">
                                            <label htmlFor="" className="input-form-label">First name*</label>
                                            <div className="flex">
                                                <div className="form-icon"><FontAwesomeIcon icon={faUser} className="text-gray text-lg"/></div>
                                                <input required type="text" className="input-form-text" placeholder="John" name="first_name"/>
                                            </div>
                                        </div>
                                        <div className="w-full">
                                            <label htmlFor="" className="input-form-label">Last name*</label>
                                            <div className="flex">
                                                <div className="form-icon"><FontAwesomeIcon icon={faUser} className="text-gray text-lg"/></div>
                                                <input required type="text" className="input-form-text" placeholder="Smith" name="last_name"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-3">
                                        <label htmlFor="" className="input-form-label">Email*</label>
                                        <div className="flex">
                                            <div className="form-icon"><FontAwesomeIcon icon={faEnvelope} className="text-gray text-lg"/></div>
                                            <input required type="email" className="input-form-email" placeholder="johnsmith@example.com" name="user_mail"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-3">
                                        <label htmlFor="" className="input-form-label">Subject*</label>
                                        <div className="flex">
                                            <div className="form-icon"><FontAwesomeIcon icon={faFileLines} className="text-gray text-lg"/></div>
                                            <input required type="text" className="input-form-text" placeholder="Web Development" name="subject"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-12">
                                        <label htmlFor="" className="input-form-label">Message*</label>
                                        <div className="flex">
                                            <div className="form-icon !items-start mt-3"><FontAwesomeIcon icon={faMessage} className="text-gray text-lg"/></div>
                                            <textarea required rows="4" cols="50" name="message" className="input-form-textarea" placeholder="Enter message here..."/>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-3">
                                        <input type="submit" value="Send message" className="btn-form-submit w-full" disabled={isSubmitting} />
                                    </div>
                                </div>
                                <AnimatePresence>
                                    {errorMessage && (
                                        <motion.div
                                            key="submitError"
                                            initial="hidden"
                                            animate="visible"
                                            exit="exit"
                                            variants={alertVariants}
                                            transition={{ duration: 0.5 }}
                                            className="alert"
                                            role="alert"
                                            id="submitError"
                                        >
                                            <strong className="font-bold">Uh oh!<br/></strong>
                                            <span className="block sm:inline">{errorMessage}</span>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                                <AnimatePresence>
                                    {isSubmitting && (
                                        <motion.div
                                            key="submitSuccess"
                                            initial="hidden"
                                            animate="visible"
                                            exit="exit"
                                            variants={alertVariants}
                                            transition={{duration: 0.5}}
                                            className="alert"
                                            role="alert"
                                            id="submitSuccess"
                                        >
                                            <span className="block sm:inline">Message sent successfully!</span>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;