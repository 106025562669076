import './App.css';
import { NavBar } from './components/NavBar';
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, {useEffect, useRef} from "react";
import { gsap } from "gsap";
import Home from "./pages";
import Footer from "./components/Footer";
import About from "./pages/About";
// import Skills from "./pages/Skills";
import Works from "./pages/Works";
import Contact from "./pages/Contact";

function App() {

    const textRef = useRef(null);
    const triggerRef = useRef(null);

    useEffect(() => {
        const tl = gsap.fromTo(
            textRef.current,
            { opacity: 0, y: 50 },
            {
                duration: 1,
                opacity: 1,
                y: 0,
                scrollTrigger: {
                    trigger: triggerRef.current,
                    start: "top center",
                    end: "bottom center",
                    scrub: true,
                    markers: false
                }
            }
        );

        return () => {
            tl.kill();
        };
    }, []);

    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('dark')
    } else {
        document.documentElement.classList.remove('dark')
    }

// Whenever the user explicitly chooses light mode
    localStorage.theme = 'light'

// Whenever the user explicitly chooses dark mode
    localStorage.theme = 'dark'

// Whenever the user explicitly chooses to respect the OS preference
    localStorage.removeItem('theme')

  return (
      <Router className="App">
        <NavBar />
        <Routes class="content">
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            {/*<Route path="/skills" element={<Skills />} />*/}
            <Route path="/works" element={<Works />} />
            <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer/>
      </Router>
  );
}

export default App;
