import React from "react";

const Footer = () => {
    return (
        <section className="footer">
            <footer>
                <hr/>
                <div className="flex flex-row flex-wrap gap-x-40 gap-y-10">
                    <div>
                        <h3 className="foot-header">Keep in touch</h3>
                        <a href="mialto:info@arjengraafland.nl">info@arjengraafland.nl<br/></a>
                        <a href="tel:+31615447401">+31 6 15447401</a>
                    </div>
                    <div className="visible sm:invisible grid grid-cols-1 content-start">
                        <p className="foot-category">Find us here</p>
                        <p>
                            Gochsedijk 71,
                        </p>
                        <p>
                            5853AB Siebengewald
                        </p>
                        <p>
                            Nederland
                        </p>
                    </div>
                        <div className="hidden sm:grid grid-cols-1 content-start">
                            <p className="foot-category">Find us here</p>
                            <p>
                                Gochsedijk 71,
                            </p>
                            <p>
                                5853AB Siebengewald
                            </p>
                            <p>
                                Nederland
                            </p>
                        </div>
                        <div className="grid grid-cols-1 content-start">
                            <p className="foot-category">Follow us</p>
                            <a href="https://github.com/a-gfld">
                                Github
                            </a>
                            <a href="https://www.linkedin.com/in/arjen-graafland-b65b2b290">
                                Linkedin
                            </a>
                        </div>
                        <div className="grid grid-cols-1 content-start">
                            <p className="foot-category">Menu</p>
                            <a href="https://arjengraafland.nl/">
                                Home
                            </a>
                            <a href="https://arjengraafland.nl/about">
                                About
                            </a>
                            <a href="https://arjengraafland.nl/works">
                                Works
                            </a>
                            <a href="https://arjengraafland.nl/contact">
                                Contact
                            </a>
                        </div>
                </div>
            </footer>
        </section>
    );
};
export default Footer;